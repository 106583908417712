var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tabbarHeaderInner",
      style: { width: _vm.tabbars.length === 1 ? "50%" : "100%" }
    },
    [
      _c(
        "el-tabs",
        {
          attrs: { stretch: true, hidefocus: "true" },
          on: { "tab-click": _vm.tabActive },
          nativeOn: {
            "!keydown": [
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "down", 40, $event.key, [
                    "Down",
                    "ArrowDown"
                  ])
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
              },
              function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "up", 38, $event.key, [
                    "Up",
                    "ArrowUp"
                  ])
                )
                  return null
                $event.stopPropagation()
                $event.preventDefault()
              }
            ]
          },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        _vm._l(_vm.tabbars, function(item, index) {
          return _c(
            "el-tab-pane",
            { key: item.className + index, attrs: { name: index.toString() } },
            [
              _c(
                "div",
                {
                  staticClass: "tabbarItem",
                  attrs: { slot: "label" },
                  slot: "label"
                },
                [
                  _c("span", {
                    staticClass: "iconfont_Me iconStyle",
                    class: item.className
                  }),
                  item.countText
                    ? _c("span", { staticClass: "countText" }, [
                        _vm._v(
                          _vm._s(_vm.handleNewVersionCount(item.countText))
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }