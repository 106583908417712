<template>
  <div class="tabbarHeaderInner" :style="{width:tabbars.length === 1?'50%':'100%'}">
    <el-tabs 
      @keydown.native.down.capture.stop.prevent 
      @keydown.native.up.capture.stop.prevent 
      v-model="activeName" 
      @tab-click="tabActive" 
      :stretch="true" 
      hidefocus="true">
      <el-tab-pane v-for="(item,index) in tabbars" :key="item.className + index" :name="index.toString()">
        <div slot="label" class="tabbarItem">
          <span class="iconfont_Me iconStyle" :class="item.className"></span>
          <span class="countText" v-if="item.countText">{{handleNewVersionCount(item.countText)}}</span>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
export default {
  props: {
    tabbars: {
      type: Array,
      default: () => {
        return [];
      }
    },
    // 默认的下标
    tabIndex: {
      type: Number,
      default: 0
    }
  },
  watch: {
    // 默认tab选中的下标
    'tabIndex': {
      handler(val) {
        if(val.toString() !== this.activeName) {
          this.activeName = val.toString();
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    activeIndex() {
      return Number(this.activeName);
    }
  },
  data() {
    return {
      // el-tabs model必须得是字符串
      activeName: '0'
    }
  },
  methods:{
    tabActive(e) {
      this.$emit('tabActive',this.activeIndex);
    }
  }
}
</script>
<style lang="stylus" scoped>
  .tabbarHeaderInner
    height 100%;
    .tabbarItem
      height 100%;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        color #8F8F8F;
        font-size 26px;
      .countText
        color #8F8F8F;
        font-size 12px;
        padding-left 3px;

</style>
<style lang="stylus">
  .tabbarHeaderInner
    .el-tabs__header
      margin 0 !important;
      .el-tabs__nav-wrap
        &:after
          height 1px !important;
          background-color #F0F0F0 !important;
        .el-tabs__active-bar
          background-color #333333 !important;
          height: 1px !important;
        .is-active
          .iconStyle
            color #333333 !important;
          .countText
            color #333333 !important;;

</style>